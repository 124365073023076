<template>
  <v-dialog
    v-model="propDisplay"
    fullscreen
    @keydown.esc="close()"
    @click:outside="close()"
  >
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <!-- progress -->
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      :confirm_function="loading.function"
      v-on:confirm="loading.dialog = false"
    >
    </common-loading>
    <case-detail
      :display="case_display"
      :propEditMode="case_edit_mode"
      :propEditedItem="case_edited_item"
      v-on:onClose="closeCase"
    ></case-detail>
    <v-card class="px-2 py-0">
      <v-card-title class="justify-center mx-4">
        <v-spacer></v-spacer>
        <v-img
          src="@/assets/img/GRT橫.jpg"
          class="navbar-brand-img ms-3"
          max-width="240"
          max-height="60"
        >
        </v-img>
        <span class="text-h3 justify-center">{{ formTitle }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-0" fluid>
          <v-row class="pa-0">
            <v-col class="pa-0">
              <customer-add-account
                title="新增客戶"
                btn_title="儲存"
                v-if="
                  editMode == edit_mode_enum.Create && isAllowCreateCustomer()
                "
                v-on:onAdd="onCreateCustomerConfirm"
                v-on:onClear="onClear"
              ></customer-add-account>
              <customer-update-account
                v-else-if="
                  editMode === edit_mode_enum.Edit && isAllowUpdateCustomer()
                "
                title="更新客戶資料"
                v-on:onUpdate="onUpdateCustomerConfirm"
                v-on:onRestore="onClear"
              ></customer-update-account>
              <customer-close
                v-else
                :export_pdf="false"
                v-on:onClose="close"
              ></customer-close>
              <customer-basic-info
                ref="basic"
                :propEditMode="propEditMode"
                :info="editedItem"
              ></customer-basic-info>
              <customer-phone-info
                ref="phone_info"
                :propEditMode="propEditMode"
                :phoneInfo="editedItem.phones"
                v-on:addPhone="addPhone"
                v-on:removePhone="removePhone"
              ></customer-phone-info>
              <customer-address-info
                ref="address_info"
                :propEditMode="propEditMode"
                :addressInfo="editedItem.addresses"
                v-on:addAddress="addAddress"
                v-on:removeAddress="removeAddress"
              >
              </customer-address-info>
              <customer-sale
                ref="customer_sale"
                :propInfo="editedItem.related_sales"
                :propEditMode="propEditMode"
                v-if="isAllowAssignSales()"
              >
              </customer-sale>
              <customer-inquire
                ref="inquire_info"
                :inquireInfo="editedItem.inquires"
                :inquireTypes="inquire_types"
                v-on:view_Case="viewCase"
              ></customer-inquire>
              <customer-case
                ref="customer_case"
                :propCases="editedItem.cases"
                v-on:view_Case="viewCase"
              ></customer-case>

              <customer-bind-customer
                ref="bind_customer"
                :propEditInfoID="editedItem.id"
                :propEditMode="propEditMode"
                :propGroups="editedItem.groups"
                :editInfo="editedItem"
                v-on:onBindChange="onBindChange"
              >
              </customer-bind-customer>
              <customer-transaction
                ref="transaction_info"
                :transactionInfo="editedItem.transactions"
                v-on:view_Case="viewCase"
              ></customer-transaction>
              <customer-add-account
                title="新增客戶"
                btn_title="儲存"
                v-if="
                  editMode == edit_mode_enum.Create && isAllowCreateCustomer()
                "
                v-on:onAdd="onCreateCustomerConfirm"
                v-on:onClear="onClear"
              ></customer-add-account>
              <customer-update-account
                v-else-if="
                  editMode === edit_mode_enum.Edit && isAllowUpdateCustomer()
                "
                title="更新客戶資料"
                v-on:onUpdate="onUpdateCustomerConfirm"
                v-on:onRestore="onClear"
              ></customer-update-account>
              <customer-close
                v-else
                :export_pdf="false"
                v-on:onClose="close"
              ></customer-close>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import CustomerBasicInfo from "./Widgets/CustomerBasicInfo.vue";
import CustomerPhoneInfo from "./Widgets/CustomerPhoneInfo.vue";
import CustomerAddressInfo from "./Widgets/CustomerAddressInfo.vue";
import CustomerBindCustomer from "./Widgets/CustomerBindCustomer.vue";
import CustomerTransaction from "./Widgets/CustomerTransaction.vue";
import CustomerAddAccount from "./Widgets/CustomerAddAccount.vue";
import CustomerUpdateAccount from "./Widgets/CustomerUpdateAccount.vue";
import CustomerClose from "./Widgets/CustomerClose.vue";
import CustomerInquire from "./Widgets/CustomerInquire.vue";
import CustomerCase from "./Widgets/CustomerCase.vue";
import { phone_type_enum, edit_mode_enum } from "@/definitions.js";
import CaseBasicInfo from "./Widgets/CaseBasicInfo.vue";
import { mapGetters } from "vuex";
import CustomerBase from "./Widgets/CustomerBase.vue";
import _ from "lodash";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonUtility from "@/util/CommonUtility.js";
import CustomerSale from "./Widgets/CustomerSale.vue";
export default {
  name: "customer-detail-dialog",
  props: {
    propEditMode: {
      type: Number,
      default: 0,
    },
    propEditItem: {},
    propDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    phone_type_enum: phone_type_enum,
    edit_mode_enum: edit_mode_enum,
    editMode: edit_mode_enum.View,
    editedItem: {},
    originItem: {},
    isCreate: false,
    inquire_types: [],
    case_display: false,
    case_edit_mode: edit_mode_enum.View,
    case_edited_item: {},
    loading: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
      confirm_function: null,
    },
    confirm: {
      dialog: false,
      title: null,
      message: null,
      function: null,
    },
  }),
  mixins: [CustomerBase],
  components: {
    CustomerBasicInfo,
    CustomerPhoneInfo,
    CustomerBindCustomer,
    CustomerAddAccount,
    CustomerUpdateAccount,
    CustomerAddressInfo,
    CustomerTransaction,
    CustomerInquire,
    CustomerClose,
    CaseBasicInfo,
    CommonLoading,
    CommonConfirm,
    CustomerCase,
    CaseDetail: () => import("./CaseDetailDialog.vue"),
    CustomerSale,
  },
  mounted() {
    this.editMode = this.propEditMode; // Assign the prop value to the data property
    this.parseCustomer(this.propEditItem);
  },
  watch: {
    propEditMode: {
      handler: function (val) {
        this.editMode = val;
      },
    },
    propEditItem: {
      handler: function (val) {
        this.parseCustomer(val);
      },
      deep: true,
    },
  },
  methods: {
    ...mapGetters([
      "getToken",
      "isAllowUpdateCustomer",
      "isAllowCreateCustomer",
    ]),
    parseCustomer(info) {
      this.editedItem = _.cloneDeep(info);
      this.originItem = _.cloneDeep(info);
      if (this.editedItem.id != null) {
        this.getCustomer(this.editedItem.id);
        console.log("get the latest customer info", this.propEditItem.id);
      }
    },
    close() {
      this.onClear();
      this.$emit("close");
    },
    onClear() {
      this.editedItem = JSON.parse(JSON.stringify(this.originItem));
      this.$refs.basic.setDefaultFocus();
      this.$refs.bind_customer.clear();
      if (this.$refs.customer_sale != null) {
        this.$refs.customer_sale.clear();
      }
    },
    getPhoneInfo() {
      let phone_info = [];
      let phones = this.$refs.phone_info.phoneInfo;
      phones.forEach((item) => {
        if (item.number != null) {
          phone_info.push(item);
        }
      });
      return phone_info;
    },
    getAddressInfo() {
      let address_info = [];
      let addresses = this.$refs.address_info.addressInfo;

      addresses.forEach((item) => {
        if (item.address != null) {
          address_info.push(item);
        }
      });
      return address_info;
    },
    getTransactionInfo() {
      let transaction_info = [];

      return transaction_info;
    },
    getInquireInfo() {
      let inquire_info = [];
      let inquires = this.$refs.inquire_info.inquireInfo;
      inquires.forEach((item) => {
        if (item.date != null && item.type != null && item.type.id != null) {
          inquire_info.push(item);
        }
      });
      return inquire_info;
    },
    getRelatedSalesInfo() {
      if (this.$refs.customer_sale == null) {
        return [];
      }
      let sales_info = this.$refs.customer_sale.sales;

      // console.log("get sales info", sales_info);
      return sales_info;
    },
    getInfo() {
      let data = {
        email: this.$refs.basic.info.email,
        full_name: this.$refs.basic.info.full_name,
        id_full: this.$refs.basic.info.id_full,
        company: this.$refs.basic.info.company,
        job: this.$refs.basic.info.job,
        customer_source: this.$refs.basic.info.customer_source,
        customer_type: this.$refs.basic.info.customer_type,
        industry: this.$refs.basic.info.industry,
        data_source: this.$refs.basic.info.data_source,
        birth: this.$refs.basic.info.birth,
        gender: this.$refs.basic.info.gender,
        groups: this.$refs.basic.getGroupInfo(),
        addresses: this.getAddressInfo(),
        phones: this.getPhoneInfo(),
        transactions: this.getTransactionInfo(),
        inquires: this.getInquireInfo(),
        related_sales: this.getRelatedSalesInfo(),
      };

      if (data.related_sales.length > 0) {
        // change the data_source into "指定"
        data.data_source = "指定";
      }
      return data;
    },
    showInfoDialog(title, message){
      this.loading = {
          dialog: true,
          title: title,
          message: message,
          show_loading: false,
          show_confirm: true,
        };
    },
    onCreateCustomerConfirm() {
      // Check assigned sales
      if(this.$refs.basic.info.full_name == "" || this.$refs.basic.info.full_name == null){
        this.showInfoDialog("確認", "請輸入客戶姓名");
        return;
      }

      if (
        this.getRelatedSalesInfo().length == 0 &&
        this.$refs.basic.info.data_source == "指定"
      ) {
        this.showInfoDialog("確認", "沒有指定業務");
        return;
      }
      this.confirm = {
        dialog: true,
        title: "確認",
        message: "確定要新增客戶嗎?",
        function: this.createCustomer,
      };
    },
    createCustomer() {
      this.confirm.dialog = false;
      this.loading = {
        dialog: true,
        title: "新增中",
        message: "新增客戶中...",
        show_loading: true,
      };
      let url = process.env.VUE_APP_SERVER_URL + "/api/v1/customers/";
      const data = this.getInfo();
      this.doHttpPost(url, data, this.createCustomerResult, this.onCreateFail);
    },
    onCreateFail(data) {
      const msg = CommonUtility.parseError(data.response.data.detail);
      this.showInfoDialog("新增失敗", msg);
    },
    createCustomerResult(data) {
      let main = data.main;
      let customer = data.customer;
      let msg = "新增客戶成功";
      if (main != null && customer != null && main.id != customer.id) {
        console.log("merge to another customer");
        msg = `新增客戶成功 合併至${main.full_name}`;
      }
      this.loading = {
        dialog: true,
        title: "新增成功",
        message: msg,
        show_loading: false,
        show_confirm: true,
        function: () => {
          this.close();
        },
      };
      this.$emit("create_customer", data);
    },
    onUpdateCustomerConfirm() {
      this.confirm = {
        dialog: true,
        title: "確認",
        message: "確定要更新客戶資料嗎?",
        function: this.updateCustomer,
      };
    },
    updateCustomer() {
      this.confirm.dialog = false;
      this.loading = {
        dialog: true,
        title: "更新中",
        message: "更新客戶資料中...",
        show_loading: true,
      };
      let id = this.$refs.basic.info.id;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers/${id}`;
      let data = this.getInfo();
      this.doHttpPut(
        url,
        data,
        this.updateCustomerResult,
        this.onUpdateFailCustomer
      );
    },

    updateCustomerResult(data) {
      console.log("update success", data);
      // get the main from data
      let main = data.main;
      let customer = data.customer;
      let msg = "更新客戶資料成功";
      if (main != null && customer != null && main.id != customer.id) {
        console.log("merge to another customer");
        msg = `更新客戶資料成功 合併至${main.full_name}`;
      }

      this.loading = {
        dialog: true,
        title: "更新成功",
        message: msg,
        show_loading: false,
        show_confirm: true,
        function: () => {
          this.close();
        },
      };
      this.$emit("update_customer", data);
    },
    onUpdateFailCustomer(data) {
      const msg = CommonUtility.parseError(data.response.data.detail);
      this.loading = {
        dialog: true,
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    addPhone() {
      console.log("append empty item");
      this.editedItem.phones.push({ type: 0, number: null });
    },
    removePhone(index) {
      console.log("remove phone:", index);
      // remove the item from this.editedItem.phones by index
      this.editedItem.phones.splice(index, 1);
    },
    addAddress() {
      this.editedItem.addresses.push({ address: null });
    },
    removeAddress(index) {
      console.log("remove address", index);
      //  remove the item from this.editedItem.addresses by index
      this.editedItem.addresses.splice(index, 1);
    },
    getPhoneByType(item, phone_type) {
      if (item.phones == null) {
        return null;
      }
      for (let i = 0; i < item.phones.length; i++) {
        let phone = item.phones[i];

        if (phone.type == phone_type) {
          return phone.number;
        }
      }
      return null;
    },
    setResultDetail(data) {
      this.editedItem = _.cloneDeep(data);
      this.originItem = _.cloneDeep(data);
      // related_sales
      if (data.related_sales != null && this.$refs.customer_sale != null) {
        console.log("set related_sales", data.related_sales);
        for (let i = 0; i < data.related_sales.length; i++) {
          this.$refs.customer_sale.onClickAdd(data.related_sales[i]);
        }
      }
    },
    onBindChange(id) {
      console.log("on bind change", id);
      if (id != null) {
        this.getCustomer(id);
      }
      this.$emit("refresh");
    },
    viewCase(item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/cases/${item.case_id}`;
      const params = {};
      this.doHttpGet(url, params, this.setCaseResult);
    },
    setCaseResult(data) {
      this.case_edited_item = data;
      this.case_edit_mode = edit_mode_enum.View;
      this.case_display = true;
    },
    closeCase() {
      this.case_edit_mode = edit_mode_enum.Hidden;
      this.case_display = false;
    },
    isAllowAssignSales() {
      if (this.propEditMode === edit_mode_enum.Create) {
        return true;
      }
      if (
        this.propEditMode === edit_mode_enum.Edit &&
        (this.editedItem.data_source === "指定" ||
          this.editedItem.data_source === "公開")
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    formTitle() {
      switch (this.editMode) {
        case this.edit_mode_enum.View:
          return "檢視客戶";
        case this.edit_mode_enum.Create:
          return "新增客戶";
        case this.edit_mode_enum.Edit:
          return "編輯客戶";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
/* @import "../../../assets/css/jub.css"; */

.download_txt:hover {
  color: blue !important;
  text-decoration: underline;
}
</style>
