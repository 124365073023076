<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("Inquire") }}</h5>
      <v-spacer></v-spacer>
    </v-card-title>
    <div class="px-6 pb-6 pt-0">
      <v-row v-for="(item, index) in inquires" :key="index" class="py-0">
        <v-col cols="1" class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >案件類型</label
          >
          <v-text-field
            hide-details
            outlined
            class="text-typo font-size-input border"
            :value="getObjectType(item.object_type)"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >案件名稱</label
          >
          <v-text-field
            hide-details
            outlined
            class="text-typo font-size-input border"
            :value="item.case_name"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >詢問事項</label
          >
          <v-text-field
            hide-details
            outlined
            class="text-typo font-size-input border border-radius-md"
            :value="item.context"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" class="py-0">
          <label class="text-xs text-typo font-weight-bolder ms-1"
            >詢問日期</label
          >
          <v-text-field
            hide-details
            outlined
            class="text-typo font-size-input border border-radius-md"
            :value="item.date"
            disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn 
            v-if="item.case_name != null"
            :ripple="false"
            elevation="0"
            color="#fff"
            class="py-5 px-6 btn-danger bg-gradient-default"
            small
            @click="$emit('view_Case', item)"
          >
            檢視
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { object_type_enum_str, object_type_enum } from "@/case.js";
import CommonUtility from "@/util/CommonUtility.js";
export default {
  name: "customer-inquire",
  props: {
    inquireInfo: {
      type: Array,
      default: () => [],
    },
    inquireTypes: {
      type: Array,
      default: () => [],
    },
  },

  data(vm) {
    return {
      object_type_enum: object_type_enum,
      object_type_enum_str: object_type_enum_str,
      land_subtypes: [],
      building_subtypes: [],
      inquires: [],
    };
  },
  mounted() {
    this.parseInquires(this.inquireInfo);
  },
  watch: {
    inquireInfo: {
      handler(val) {
        this.parseInquires(val);
      },
      deep: true,
    },
  },
  methods: {
    parseInquires(val) {
      this.inquires = val;
    },
    addInquire() {
      this.$emit("addInquire");
    },
    removeInquire(item) {
      // get the index of the item in array (inquireInfo)
      const index = this.inquireInfo.indexOf(item);

      this.$emit("removeInquire", index);
    },
    getTypeName(type) {
      return CommonUtility.getText(type, object_type_enum_str);
    },
    getObjectType(type) {
      if (type == "B") {
        return "建物";
      } else if (type == "L") {
        return "土地";
      }
      return type;
    },
  },
};
</script>
