var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()},"click:outside":function($event){return _vm.close()}},model:{value:(_vm.propDisplay),callback:function ($$v) {_vm.propDisplay=$$v},expression:"propDisplay"}},[_c('common-confirm',{attrs:{"title":_vm.confirm.title,"message":_vm.confirm.message,"confirm_dialog":_vm.confirm.dialog,"confirm_function":_vm.confirm.function},on:{"confirm":function($event){_vm.confirm.dialog = false},"cancel":function($event){_vm.confirm.dialog = false}}}),_c('common-loading',{attrs:{"title":_vm.loading.title,"message":_vm.loading.message,"loading_dialog":_vm.loading.dialog,"show_loading":_vm.loading.show_loading,"show_confirm":_vm.loading.show_confirm,"confirm_function":_vm.loading.function},on:{"confirm":function($event){_vm.loading.dialog = false}}}),_c('case-detail',{attrs:{"display":_vm.case_display,"propEditMode":_vm.case_edit_mode,"propEditedItem":_vm.case_edited_item},on:{"onClose":_vm.closeCase}}),_c('v-card',{staticClass:"px-2 py-0"},[_c('v-card-title',{staticClass:"justify-center mx-4"},[_c('v-spacer'),_c('v-img',{staticClass:"navbar-brand-img ms-3",attrs:{"src":require("@/assets/img/GRT橫.jpg"),"max-width":"240","max-height":"60"}}),_c('span',{staticClass:"text-h3 justify-center"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0"},[(
                _vm.editMode == _vm.edit_mode_enum.Create && _vm.isAllowCreateCustomer()
              )?_c('customer-add-account',{attrs:{"title":"新增客戶","btn_title":"儲存"},on:{"onAdd":_vm.onCreateCustomerConfirm,"onClear":_vm.onClear}}):(
                _vm.editMode === _vm.edit_mode_enum.Edit && _vm.isAllowUpdateCustomer()
              )?_c('customer-update-account',{attrs:{"title":"更新客戶資料"},on:{"onUpdate":_vm.onUpdateCustomerConfirm,"onRestore":_vm.onClear}}):_c('customer-close',{attrs:{"export_pdf":false},on:{"onClose":_vm.close}}),_c('customer-basic-info',{ref:"basic",attrs:{"propEditMode":_vm.propEditMode,"info":_vm.editedItem}}),_c('customer-phone-info',{ref:"phone_info",attrs:{"propEditMode":_vm.propEditMode,"phoneInfo":_vm.editedItem.phones},on:{"addPhone":_vm.addPhone,"removePhone":_vm.removePhone}}),_c('customer-address-info',{ref:"address_info",attrs:{"propEditMode":_vm.propEditMode,"addressInfo":_vm.editedItem.addresses},on:{"addAddress":_vm.addAddress,"removeAddress":_vm.removeAddress}}),(_vm.isAllowAssignSales())?_c('customer-sale',{ref:"customer_sale",attrs:{"propInfo":_vm.editedItem.related_sales,"propEditMode":_vm.propEditMode}}):_vm._e(),_c('customer-inquire',{ref:"inquire_info",attrs:{"inquireInfo":_vm.editedItem.inquires,"inquireTypes":_vm.inquire_types},on:{"view_Case":_vm.viewCase}}),_c('customer-case',{ref:"customer_case",attrs:{"propCases":_vm.editedItem.cases},on:{"view_Case":_vm.viewCase}}),_c('customer-bind-customer',{ref:"bind_customer",attrs:{"propEditInfoID":_vm.editedItem.id,"propEditMode":_vm.propEditMode,"propGroups":_vm.editedItem.groups,"editInfo":_vm.editedItem},on:{"onBindChange":_vm.onBindChange}}),_c('customer-transaction',{ref:"transaction_info",attrs:{"transactionInfo":_vm.editedItem.transactions},on:{"view_Case":_vm.viewCase}}),(
                _vm.editMode == _vm.edit_mode_enum.Create && _vm.isAllowCreateCustomer()
              )?_c('customer-add-account',{attrs:{"title":"新增客戶","btn_title":"儲存"},on:{"onAdd":_vm.onCreateCustomerConfirm,"onClear":_vm.onClear}}):(
                _vm.editMode === _vm.edit_mode_enum.Edit && _vm.isAllowUpdateCustomer()
              )?_c('customer-update-account',{attrs:{"title":"更新客戶資料"},on:{"onUpdate":_vm.onUpdateCustomerConfirm,"onRestore":_vm.onClear}}):_c('customer-close',{attrs:{"export_pdf":false},on:{"onClose":_vm.close}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }