<template>
  <v-card class="card-shadow border-radius-xl mt-2">
    <v-card-title>
      <h5 class="text-h5 font-weight-bold text-typo">關聯業務</h5>
    </v-card-title>
    <v-card-text>
      <v-col cols="12" v-if="sales.length > 0">
        <v-data-table
          :headers="sale_headers"
          :options.sync="options"
          :items="sales"
          :server-items-length="sales.length"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.last_name="{ item }">
            {{ item.user.last_name }}{{ item.user.first_name }}
          </template>
          <template v-slot:item.type="{ item }">
            <div v-if="readonly">
              {{ item.business_type | getText(business_type_str) }}
            </div>
            <v-select
              v-else
              :items="business_type_str"
              v-model="item.business_type"
              dense
              hide-details
              class="
                input-style
                font-size-input
                border-radius-md
                select-style
                d-flex
                align-center
              "
              outlined
              single-line
            >
            </v-select>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="!readonly"
              :ripple="false"
              :elevation="0"
              class="
                font-weight-bold
                text-xs
                btn-default
                bg-gradient-danger
                py-5
                px-6
                mt-6
                mb-2
                me-2
                text-end
              "
              @click="removeSale(item)"
            >
              移除
            </v-btn>
          </template>
        </v-data-table>
        <!-- <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#cb0c9f"
          v-model="page"
          :length="getPageCount()"
          circle
          :total-visible="15"
        ></v-pagination> -->
      </v-col>
    </v-card-text>

    <v-card-text v-if="propEditMode > edit_mode_enum.View">
      <h6 class="text-h6 font-weight-bold text-grt">搜尋業務</h6>
      <v-row>
        <v-col>
          <v-text-field
            v-model="userInfo.username"
            hide-details
            outlined
            placeholder="帳號"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="userInfo.last_name"
            hide-details
            outlined
            placeholder="姓"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="userInfo.first_name"
            hide-details
            outlined
            placeholder="名"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="userInfo.email"
            hide-details
            outlined
            placeholder="Email"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="userInfo.mobile_phone"
            hide-details
            outlined
            placeholder="手機"
            class="font-size-input placeholder-er border border-radius-md"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn
            class="font-weight-bolder btn-default bg-gradient-default mr-2"
            :ripple="false"
            elevation="0"
            @click="onSearchClick()"
          >
            搜尋業務

            <v-icon right dark> mdi-account-search-outline </v-icon>
          </v-btn>

          <v-btn
            class="font-weight-bolder btn-outline-secondary mr-2"
            :ripple="false"
            elevation="0"
            color="#fff"
            @click="resetSearch()"
          >
            {{ $t("Reset") }}

            <v-icon right dark> mdi-restart </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            :server-items-length="items_count"
            :loading="loading"
            hide-default-footer
            disable-pagination
            v-model="select_user"
            @click:row="rowClick"
            single-select
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                :ripple="false"
                :elevation="0"
                class="
                  font-weight-bold
                  text-xs
                  btn-default
                  bg-gradient-primary
                  py-5
                  px-6
                  mt-6
                  mb-2
                  me-2
                  text-end
                "
                @click="onClickAdd(item)"
              >
                加入
              </v-btn>
            </template>
          </v-data-table>
          <v-pagination
            v-if="items_count > 0"
            prev-icon="fa fa-angle-left"
            next-icon="fa fa-angle-right"
            class="pagination"
            color="#cb0c9f"
            v-model="page"
            :length="getPageCount()"
            circle
            :total-visible="10"
          ></v-pagination>
        </v-col>
      </v-row>
      <!-- </div> -->
    </v-card-text>
  </v-card>
</template>
<script>
import { edit_mode_enum } from "@/definitions.js";
import UserMixin from "@/components/CRM/UserMixin.vue";
import { business_type_enum, business_type_str } from "@/case.js";
import _ from "lodash";

export default {
  name: "customer-sales",
  props: {
    propInfo: {
      type: Array,
      default: () => [],
    },
    propEditMode: {
      type: Number,
      default: edit_mode_enum.View,
    },
    // prop_case_id: {
    //   type: Number,
    //   default: 0,
    // },
    // propCaseName: {
    //   type: String,
    //   default: "",
    // },
    // propCaseType: {
    //   type: String,
    //   default: "",
    // },
  },
  mixins: [UserMixin],
  data() {
    return {
      business_type_enum: business_type_enum,
      business_type_str: business_type_str,
      edit_mode_enum: edit_mode_enum,
      select_user: [],
      name: null,
      userInfo: {
        username: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        exclude_super_user: true,
        is_active: true,
      },
      sales: [],
      edit_sale_headers: [
        {
          text: "姓名",
          cellClass: "border-bottom",
          sortable: false,
          value: "last_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "手機",
          cellClass: "border-bottom",
          sortable: false,
          value: "user.mobile_phone",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Email",
          cellClass: "border-bottom",
          sortable: false,
          value: "user.email",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        // {
        //   text: "業績",
        //   cellClass: "border-bottom",
        //   sortable: false,
        //   value: "performance",
        //   width: "20%",
        //   class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        // },
        {
          text: "操作",
          cellClass: "border-bottom",
          sortable: false,
          align: "end",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
      ],
      readonly_sale_headers: [
        {
          text: "姓名",
          cellClass: "border-bottom",
          sortable: false,
          value: "last_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "手機",
          cellClass: "border-bottom",
          sortable: false,
          value: "mobile_phone",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Email",
          cellClass: "border-bottom",
          sortable: false,
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "業務類型",
          cellClass: "border-bottom",
          sortable: false,
          width: "20%",
          value: "type",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
      ],
      user_timer: null,
      origin_sales_id: [],
    };
  },
  watch: {
    propInfo: {
      handler(val) {
        console.log("watch info", val);
      },
      deep: true
    }
  },
  mounted() {
    console.log("mounted", this.propInfo);
    this.parseRelatedInfo(this.propInfo);

  },
  computed: {
    sale_headers() {
      console.log("edit mode", this.propEditMode);
      if (this.propEditMode == edit_mode_enum.View) {
        return this.readonly_sale_headers;
      }
      return this.edit_sale_headers;
    },
    readonly() {
      return this.propEditMode == edit_mode_enum.View;
    },
  },
  watch: {
    page() {
      this.getUserByCondition();
    },
  },
  methods: {
    onSearchClick() {
      this.page = 1;
      this.getUserByCondition();
    },
    parseRelatedInfo(val) {
      console.log("parse sales info", val);
      if (val != null) {
        this.origin_sales_id = val.map((obj) => {
          return obj.id;
        });
      }
      this.setSales(val);
    },

    setSales(data) {
      this.sales = [];
      console.log("!!! set sales", data);
      for (let i = 0; i < data.length; i++) {
        this.addSale(data[i]);
      }
    },
    resetSearch() {
      this.userInfo = {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
        exclude_super_user: true,
        is_active: true,
      };
      this.items = [];
      this.items_count = 0;
    },
    clear() {
      console.log("clear sales");
      this.sales = [];
      this.items = [];
      this.items_count = 0;
      this.select_user = [];
    },
    close() {
      this.edit_customer_mode = this.edit_mode_enum.View;
    },
    rowClick(item, row) {
      this.select_user = [item];
      console.debug(row);
    },
    onCreateCustomerDone(item) {
      this.items.push(item);
      this.items_count = this.items.length;
    },
    onCreateCustomer() {
      console.log("onCreateCustomer");
      this.edited_customer_item = JSON.parse(
        JSON.stringify(this.defaultCustomer)
      );
      this.edit_customer_mode = this.edit_mode_enum.Create;
    },
    onViewUser(item) {
      console.log("onViewUser", item);
    },
    onClickAdd(item) {
      let add_item = {
        id: null,
        business_type: null,
        performance: null,
        user: item,
        user_id: item.id,
        case_name: this.propCaseName,
        type: this.propCaseType,
        readonly: false,
      };
      console.log("onClickAdd", add_item);
      const added = this.addSale(add_item);
      if (added) {
        this.$emit("addSale", add_item);
      }
    },

    addSale(item) {
      // check id already exist in inquires
      let exist = this.sales.find((x) => x.user_id === item.user_id);
      if (exist) {
        return false;
      }
      console.log("addSale", item);
      

      this.sales.push(item);
      return true;
    },
    removeSale(item) {
      console.log("remove by user id ", item.user_id);
      this.sales = this.sales.filter((x) => x.user_id !== item.user_id);
      this.$emit("removeSale", item);
    },
    getRemoveSales() {
      console.log("before ", this.origin_sales_id);

      let update_ids = this.sales.map((obj) => {
        return obj.id;
      });
      let remove_ids = this.origin_sales_id.filter((x) => {
        return !update_ids.includes(x);
      });
      return remove_ids;
    },
  },
};
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>
